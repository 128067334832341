/* Contact Section Styles */
.contact-section {
    background-color: #1d1e20;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    padding: 20px;
  }
  
  .contact-logo {
    height: 80px;
    margin-bottom: 20px;
  }
  
  .contact-text {
    color: #ffffff;
    font-family: "Inter", sans-serif;
    font-size: 18px;
    margin-bottom: 20px;
    max-width: 600px;
  }
  
  .contact-button {
    display: inline-block; /* Ensures the element behaves like a button */
    background-color: transparent;
    border: 1px solid #ffffff;
    color: #ffffff;
    font-size: 18px;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 25px;
    transition: background-color 0.3s, color 0.3s;
    text-decoration: none; /* Removes underline from the link */
  }
  
  .contact-button:hover {
    background-color: #ffffff;
    color: #1d1e20;
  }
  
  