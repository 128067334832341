/* Main landing page styles */
.landing-page {
  background-color: #1d1e20;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}

/* Container styles */
.container {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

/* Alignment wrapper to align logo and Heading */
.alignment-wrapper {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center content vertically */
  height: 100vh; /* Full height to center content vertically */
  box-sizing: border-box;
}

/* Header styles */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  position: absolute; /* Keep header fixed at the top */
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

/* Logo container styles */
.logo-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Left section to contain the logo and text */
.left-section {
  display: flex;
  align-items: center;
}

/* Right section for 'GET IN TOUCH' */
.right-section {
  margin-left: auto;
}

/* Logo styles */
.logo {
  height: 47px;
}

/* "Vallpoint" text styles */
.text-wrapper {
  color: #ffffff;
  font-family: "Inter-Bold", Helvetica, Arial, sans-serif;
  font-size: 26px;
  font-weight: 700;
  letter-spacing: -0.78px;
  white-space: nowrap;
}

/* Heading alignment */
.heading-instance {
  margin-top: 0;
}

/* "GET IN TOUCH" text styles */
.get-in-touch {
  color: #ffffff;
  font-family: "Vercetti-Regular", Helvetica, Arial, sans-serif;
  font-size: 20px;
  text-decoration: underline;
  cursor: pointer;
  white-space: nowrap;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .header {
    flex-wrap: wrap;
    justify-content: center;
  }

  .left-section,
  .right-section {
    width: 100%;
    justify-content: center;
  }

  .logo {
    height: 40px;
  }

  .text-wrapper {
    font-size: 22px;
  }

  .get-in-touch {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .header {
    flex-direction: row; /* Change from column to row */
    align-items: center;
    justify-content: space-between; /* Space out the items */
    padding: 0 15px; /* Add horizontal padding */
  }

  .left-section,
  .right-section {
    width: auto; /* Allow content to size naturally */
  }

  .logo-container {
    gap: 5px; /* Reduce gap between logo and text */
  }

  .logo {
    height: 25px; /* Reduce logo size */
  }

  .text-wrapper {
    font-size: 14px; /* Reduce font size */
  }

  .get-in-touch {
    font-size: 14px; /* Reduce font size */
  }

  .alignment-wrapper {
    padding: 0 10px;
  }
}
