/* Heading.css */

/* Base styles */
.heading {
  max-width: 955px;
  width: 100%;
  margin: 0; /* Ensure there's no unintended margin */
}

/* Line wrapper styles */
.line-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the start (left) */
  margin-bottom: 10px;
}

/* First line styles */
.first-line {
  flex-direction: row;
  align-items: baseline;
  flex-wrap: wrap;
}

/* Line text styles */
.line-text {
  color: #FFFFFF;
  font-family: "Vercetti-Regular", Helvetica, Arial, sans-serif;
  font-size: 70px;
  font-weight: 400;
  letter-spacing: -3.5px;
  line-height: 1;
  margin: 0;
}

/* Scrolling text container */
.scrolling-text-container {
  display: flex;
  align-items: baseline;
}

/* Scrolling text styles */
.scrolling-text {
  display: inline-block;
  overflow: hidden;
  height: 83px;
}

/* Scrolling text inner container */
.scrolling-text-inner {
  transition: transform 0.5s ease-in-out;
}

/* Scrolling text items */
.scrolling-text-item {
  height: 83px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  font-family: "Vercetti-Regular", Helvetica, Arial, sans-serif;
  font-size: 70px;
  font-weight: 400;
  letter-spacing: -3.5px;
  line-height: 1;
}

/* SVG line styles */
.line-svg {
  width: 100%;
  height: auto;
  min-height: 1px;
  margin-bottom: 10px;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .line-text,
  .scrolling-text-item {
    font-size: 60px;
    letter-spacing: -3px;
  }
  .scrolling-text,
  .scrolling-text-item {
    height: 70px;
  }
}

@media (max-width: 768px) {
  .line-text,
  .scrolling-text-item {
    font-size: 45px;
    letter-spacing: -2px;
  }
  .scrolling-text,
  .scrolling-text-item {
    height: 55px;
  }
}

@media (max-width: 480px) {
  .heading {
    max-width: 100%;
  }

  .line-text,
  .scrolling-text-item {
    font-size: 30px;
    letter-spacing: -1px;
    text-align: center;
  }

  .scrolling-text,
  .scrolling-text-item {
    height: 40px;
  }

  /* Center all lines on mobile */
  .line-wrapper {
    align-items: center;
  }

  /* Adjust first line */
  .first-line {
    margin-top: 20px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .scrolling-text-container {
    justify-content: center;
  }

  /* Center the scrolling text */
  .scrolling-text-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .scrolling-text-item {
    width: 100%;
    text-align: center;
  }

  /* Adjust SVG line margins */
  .line-svg {
    margin-bottom: 5px;
  }
}
