/* Global Styles */
body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #1d1e20;
    color: white;
  }
  
  /* Main container */
  .integration-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
    box-sizing: border-box;
  }
  
  /* Square Wrapper */
  .square-wrapper {
    background-color: #333539;
    padding: 50px;
    padding-top: 65px;
    padding-bottom: 65px;
    border-radius: 15px;
    margin-right: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* White Box */
  .white-box {
    background-color: white;
    width: 350px;  /* Default width */
    height: 200px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  /* Telegram Icon */
  .telegram-icon {
    width: 50px;
    margin-bottom: 10px;
  }
  
  /* Status Text */
  .status-text {
    color: black;
    margin-bottom: 10px;
    font-size: 18px;
  }
  
  /* Progress Bar */
  .progress-bar {
    width: 80%;
    height: 10px;
    background-color: #ccc;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 10px;
  }
  
  .progress {
    height: 100%;
    background-color: #215fff;
    animation: loading 3s linear forwards;
  }
  
  @keyframes loading {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }
  
  /* Checkmark Wrapper */
  .checkmark-wrapper {
    width: 40px;
    height: 40px;
    border: 2px solid #215fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  
  .checkmark {
    font-size: 24px;
    color: #215fff;
    font-weight: bold;
  }
  
  /* Fade-in Wrapper */
  .fade-in-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  /* Text Section */
  .text-section {
    max-width: 500px; 
    text-align: left;
  }
  
  .text-section h2 {
    margin-bottom: 10px;
    font-size: 28px;
  }
  
  .text-section .underline {
    width: 100%;
    height: 2px;
    background-color: white;
    margin-bottom: 20px;
  }
  
  .text-section p {
    margin-bottom: 10px;
    font-size: 16px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .integration-container {
      flex-direction: column;
      height: auto;
      padding: 20px; /* Add some padding */
    }
  
    .square-wrapper {
      margin-right: 0;
      margin-bottom: 20px;
      width: 70%;  /* Make the wrapper smaller on mobile */
      max-width: 400px; /* Set a maximum width */
    }
  
    .white-box {
      width: 100%; /* Make the white box fit the smaller wrapper */
    }
  
    .text-section {
      text-align: left;
    }
  }
  
  @media (max-width: 480px) {
    .square-wrapper {
      width: 70%;  /* Further reduce size on smaller screens */
    }
  }
  