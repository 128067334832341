/* Social.css */

body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #1d1e20;
    color: white;
  }
  
  /* Main container */
  .social-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
    box-sizing: border-box;
    flex-wrap: wrap; /* Allow wrapping for smaller screens */
  }
  
  /* Image wrapper */
  .image-wrapper {
    background-color: #333539;
    padding: 30px;
    border-radius: 10px;
    margin-right: 50px;
    box-sizing: border-box;
    flex: 1;
    min-width: 300px;
    max-width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .social-image {
    width: 100%;
    height: auto;
    max-width: 300px;
  }
  
  /* Text section styling */
  .text-section {
    flex: 1;
    max-width: 500px;
    text-align: left;
    padding: 20px;
  }
  
  .text-section h2 {
    margin-bottom: 10px;
    font-size: 28px;
  }
  
  .text-section .underline {
    width: 100%;
    height: 2px;
    background-color: white;
    margin-bottom: 20px;
  }
  
  .text-section p {
    margin-bottom: 10px;
    font-size: 16px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .social-container {
        flex-direction: column;
        height: 100vh; /* Full height for centering */
        align-items: center;
        justify-content: center; /* Center content vertically */
    }
  
    .image-wrapper {
      margin-right: 0;
      margin-bottom: 20px;
      width: 100%;
      max-width: 400px;
      padding: 20px;
    }
  
    .text-section {
      text-align: center;
    }
  }
  