/* Global styles */
body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #1d1e20;
    color: white;
  }
  
  /* Main container */
  .upload-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
    box-sizing: border-box;
    flex-wrap: wrap; /* Allow wrapping for smaller screens */
  }
  
  /* Drop zone wrapper */
  .drop-zone-wrapper {
    background-color: #333539;
    padding: 30px;
    border-radius: 10px;
    margin-right: 50px;
    box-sizing: border-box;
    flex: 1;
    min-width: 300px;
    max-width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Drop zone styling */
  .drop-zone {
    width: 100%; /* Make it take up full width */
    height: 300px;
    border: 2px dashed #ccc;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    text-align: center;
  }
  
  .drop-zone.idle {
    color: #ccc;
  }
  
  .drop-zone.hover {
    background-color: #215fff;
    color: white;
  }
  
  .drop-zone.analyzing {
    background-color: #444;
    color: white;
  }
  
  .drop-zone.complete {
    background-color: #4caf50;
    color: white;
  }
  
  /* Progress bar styling */
  .progress-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
  }
  
  .progress-bar {
    width: 100%;
    height: 8px;
    background-color: #ccc;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 10px;
  }
  
  .progress {
    height: 100%;
    background-color: #215fff;
    animation: loading 3s linear forwards;
  }
  
  @keyframes loading {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }
  
  /* Text section styling */
  .text-section {
    flex: 1;
    max-width: 500px;
    text-align: left;
    padding: 20px;
  }
  
  .text-section h2 {
    margin-bottom: 10px;
    font-size: 28px;
  }
  
  .text-section .underline {
    width: 100%;
    height: 2px;
    background-color: white;
    margin-bottom: 20px;
  }
  
  .text-section p {
    margin-bottom: 10px;
    font-size: 16px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .upload-container {
      flex-direction: column;
      height: auto;
      align-items: center; /* Ensure elements stay centered */
    }
  
    .drop-zone-wrapper {
      margin-right: 0;
      margin-bottom: 20px;
      width: 100%; /* Full width on small screens */
      max-width: 400px; /* Limit max width */
      padding: 20px;
    }
  
    .text-section {
      text-align: center; /* Center text for mobile */
    }
  }
  